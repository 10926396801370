import React from "react";
import { gql, useQuery } from "@apollo/client";
import { Link } from "gatsby";
import RuntimeProductImage from "./RuntimeProductImage";

const GET_BESTSELLERS = gql`
  query ($numProducts: Int!) {
    products(first: $numProducts, sortKey: BEST_SELLING) {
      nodes {
        id
        title
        handle
        description
        availableForSale
        priceRange {
            minVariantPrice {
              amount
              currencyCode
            }
            maxVariantPrice {
              amount
              currencyCode
            }
        }
        
        featuredImage {
          originalSrc
          width
          height
          url
        }
      }
    }
  }
`;

const Bestsellers = () => {
  const { data } = useQuery(GET_BESTSELLERS, {
    variables: {
      numProducts: 6,
    },
    fetchPolicy: "no-cache" 
  });

  return (
    <section className="my-8 px-4">
      <div className="grid sm:grid-cols-1 content-center md:grid-cols-2 lg:grid-cols-3 gap-8">
        {data &&
          data.products.nodes.map((product) => {
            return (
              <div
                key={product.id}
                className="w-full mx-auto bg-white rounded-lg border border-gray-200 shadow-md  "
              >
                <Link
                  to={`/catalog/${product.handle}`}
                  state={{ id: product.id }}

                >
                  <RuntimeProductImage
                    className="rounded-t-lg h-[300px]"
                    storefrontProduct={product.featuredImage}
                    alt="Image"
                  />
                </Link>
                <div className="p-5">
                  <Link
                    to={`/catalog/${product.handle}`}
                    state={{ id: product.id }}
  
                  >
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 ">
                      {product.title}
                    </h5>
                  </Link>
                  <p className="mb-3 line-clamp-4 font-normal text-gray-700">
                    {product.description}
                  </p>

                  <p className="mb-2 text-lg font-bold tracking-tight text-gray-900">
                    {product.priceRange.minVariantPrice.amount === product.priceRange.maxVariantPrice.amount ? `${product.priceRange.minVariantPrice.amount} ${product.priceRange.minVariantPrice.currencyCode}` : ` Starting from: ${product.priceRange.minVariantPrice.amount} ${product.priceRange.minVariantPrice.currencyCode}`}
                  </p>

                  <Link
                    to={`/catalog/${product.handle}`}
                    state={{ id: product.id }}
                    className="inline-flex items-center py-2 px-3 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300"
                  >
                    View
                    <svg
                      aria-hidden="true"
                      className="ml-2 -mr-1 w-4 h-4"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      ></path>
                    </svg>
                  </Link>
                </div>
              </div>
            );
          })}
      </div>
    </section>
  );
};
export default Bestsellers;
